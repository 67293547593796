<template>
    <div class="coupon">
        <div class="tab-bar">
            <span
                @click="tab(index)"
                v-for="(item,index) in items"
                :key="index"
                :class="{couponactive : index==curId}"
            >
            {{item.name}}
            </span>
        </div>
        <div class="tab-cont">
            <div class="gift">
                <ul v-if="couponList.length > 0">
                    <li v-for="(item,index) in couponList" :key="index">
                        <div class="gift-msg">
                            <div><img :src="staticUrl+item.cover" alt=""></div>
                            <div>
                                <p>{{item.promo_name}}</p>
                                <!-- <p><span>Android</span><span>ios</span></p> -->
                            </div>
                        </div>
                        <div class="gift-code">
                            <p :title="!item.promo_code?(item.status == 1?$t('商品券码会发送到您的手机或邮箱。'):$t('商品券码已发送')):''">
                                <span v-if="curId === 0">{{$t('礼品券')}}</span>
                                <span v-else>{{$t('礼包码')}}</span>
                                ：{{item.promo_code?item.promo_code:$t('商品券码')}}
                            </p>
                            <p><span :class="item.promo_code?'active':'inactive'" @click="copyCode(item.promo_code)">{{$t('復制券碼')}}</span></p>
                        </div>
                    </li>
                </ul>
                <p v-else>{{$t('暂无任何礼券')}}</p>
            </div>
            <!-- <div v-show="curId == 1">
                <ul><li>22</li></ul>
            </div> -->
            <div class="pagin" v-if="couponList.length > 0">
                <a-pagination v-model="current" :total="total" :pageSize="pageSize" @change="handleListChange" show-less-items />
            </div>
        </div>
    </div>
</template>

<script>
import request from '../../api/request'
export default {
    data(){
        return{
            curId: 0,
            staticUrl: request.staticUrl,
            items:[
                {name:this.$t('礼包码')},
                {name:this.$t('礼品券')}
            ],
            current: 1,
            total: 1,
            pageSize:4,
            couponList:[]
        }
    },
    mounted(){
        this.getCoupon(1)
        
    },
    methods:{
        tab(index) {
            let _this = this
            _this.current = 1
            _this.curId = index
            _this.getCoupon(_this.curId+1)
        },
        getCoupon(num){
            let _this = this
            request.get('/promocodes?promo_type='+num, {page:_this.current,limit:_this.pageSize}).then(function (res) {
                if (res.status == 200) {
                    _this.total = res.data.paging.total
                    _this.couponList = res.data.results
                }else{
                    _this.$message.error(res.detail);
                }
            })
        },
        copyCode(code) {
            if (code) {
                let _this = this
                var input = document.createElement("input"); // 直接构建input
                input.value = code; // 设置内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                _this.$message.success(_this.$t("復制成功"));
            }
        },
        handleListChange(current, pageSize) {
            this.current = current;
            if (pageSize != null && pageSize != "") {
                this.pageSize = pageSize;
            }
            this.getCoupon(this.curId+1)
        }
    }
}
</script>

<style lang="scss">
.coupon{
    margin-top: 20px;
    .tab-bar{
        span{
            cursor: pointer;
            font-size: 20px;
            display: inline-block;
            margin-right: 30px;
            padding-bottom: 10px;
        }
    }
    .tab-cont{
        margin-top: 50px;
        .gift{
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    width: 45%;
                    margin: 20px 10px;
                    background-color: #fff;
                    .gift-msg{
                        display: flex;
                        border-bottom: 1px solid #E1E1E1;
                        padding: 10px;
                        img{
                            width: 120px;
                            height: 120px;
                            border-radius: 10px;
                            margin-top: -30px;
                            margin-right: 20px;
                        }
                        p{
                            margin:10px 20px;
                            &:nth-child(1){
                                color: #525456;
                                font-size: 22px;
                            }
                            span{
                                display: inline-block;
                                margin-right: 30px;
                            }
                        }
                    }
                    .gift-code{
                        display: flex;
                        padding: 10px;
                        p{
                            flex-grow: 1;
                            color: #80777C;
                            font-size: 18px;
                            &:nth-child(2){
                                text-align: right;
                                span{
                                    font-size: 14px;
                                    color: #fff;
                                    border-radius: 40px;
                                    display: inline-block;
                                    padding: 2px 10px;
                                    white-space: nowrap;
                                }
                                .active {
                                    cursor: pointer;
                                    background-color: #C6A86C;
                                }
                                .inactive {
                                    background-color: rgb(217, 217, 217);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .couponactive{
        color: #3E46FE;
        border-bottom: 1px solid #3E46FE;
    }
    .pagin{
        text-align: center;
        margin: 50px 0;
    }
}
</style>